<template>
  <div v-if="permissions.u">
    <button-top :must-icon="false" tooltip="Guardar rol" name="Guardar"
      @action="update"></button-top>

    <p class="my-1 text-dark font-weight-bold h3">Permisos del rol</p>
    <span v-for="(item, ind) in items" :key="ind" class=" w-100 mb-3">
      <b-table :items="item.rows" :fields="fields" :current-page="item.current_page" :per-page="per_page" bordered small>
        <template #thead-top="data">
          <b-tr>
            <b-th class="bg-dark text-white" colspan="5">{{ item.name }}</b-th>
          </b-tr>
        </template>
        <template #cell(s)="row">
          <b-row align-h="center">
            <b-form-checkbox v-model="row.item.s" name="show" @change="showValid(ind, row.item)" :value="1"
              :unchecked-value="0">
            </b-form-checkbox>
          </b-row>
        </template>

        <template #cell(c)="row">
          <b-row align-h="center">
            <b-form-checkbox v-model="row.item.c" name="create" :disabled="!row.item.s" :value="1" :unchecked-value="0">
            </b-form-checkbox>
          </b-row>
        </template>

        <template #cell(u)="row">
          <b-row align-h="center">
            <b-form-checkbox v-model="row.item.u" name="update" :disabled="!row.item.s" :value="1" :unchecked-value="0">
            </b-form-checkbox>
          </b-row>
        </template>

        <template #cell(d)="row">
          <b-row align-h="center">
            <b-form-checkbox v-model="row.item.d" name="delete" :disabled="!row.item.s" value="1" unchecked-value="0">
            </b-form-checkbox>
          </b-row>
        </template>
      </b-table>
      <b-row class="mt-1">
        <b-col>
          <b-pagination v-model="item.current_page" :total-rows="item.rows.length" :per-page="per_page"
            aria-controls="my-table"></b-pagination>
        </b-col>
        <b-col cols="6"></b-col>
      </b-row>
    </span>
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      id: "",
      fields: [
        {
          key: 'name',
          label: 'Permiso',
          thStyle: { width: "52%" },
        },

        {
          key: 's',
          label: 'Leer',
          thClass: 'text-center',
          thStyle: { width: "12%" },
        },

        {
          key: 'c',
          label: 'Escribir',
          thClass: 'text-center',
          thStyle: { width: "12%" },
        },

        {
          key: 'u',
          label: 'Actualizar',
          thClass: 'text-center',
          thStyle: { width: "12%" },
        },
        {
          key: 'd',
          label: 'Borrar',
          thClass: 'text-center',
          thStyle: { width: "12%" },
        },
      ],
      items: [],
      per_page: 5,
    }
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.id = this.$route.params.id;
      await this.getPermission('u');
      await this.getModules();
    },

    getModules() {
      this.loading();
      let item = [];
      this.$http
        .get(`/configuration/module/show-rol/${this.id}`)
        .then(response => {
          const data = response.data.data;
          this.setHeaderTitle(`Editar rol ${response.data.profile.name.charAt(0).toUpperCase() + response.data.profile.name.slice(1).toLowerCase()
            }`);
          for (let i in data) {
            const index = item.findIndex(
              (p) => p.name === data[i].type_module
            )
            if (index < 0) {
              item.push(
                {
                  name: data[i].type_module,
                  rows: [data[i]],
                  current_page: 1,
                }
              );
            } else {
              item[index].rows.push(data[i]);
            }
          }
          this.items = item;
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: 'profiles' })
          }
          this.notify("Error", error.response.data.message, 'danger')
        }).finally(
          () => {
            this.not_loading();
          }
        )
    },

    showValid(ind, item) {
      if (!item.s) {
        const index = this.items[ind].rows.findIndex(
          (p) => p.id === item.id
        )
        if (index >= 0) {
          this.items[ind].rows[index].c = 0;
          this.items[ind].rows[index].u = 0;
          this.items[ind].rows[index].d = 0;
        }
      }
    },

    update() {
      this.loading();
      const DataForm = new FormData();
      let i = 0;
      DataForm.append('profile_id', this.id);
      this.items.forEach((item) => {
        item.rows.forEach((it) => {
          DataForm.append(`permissions[${i}][permission_module_profile_id]`, it.permission_module_profile_id);
          DataForm.append(`permissions[${i}][id]`, it.id);
          DataForm.append(`permissions[${i}][s]`, it.s);
          DataForm.append(`permissions[${i}][c]`, it.c);
          DataForm.append(`permissions[${i}][u]`, it.u);
          DataForm.append(`permissions[${i}][d]`, it.d);
          i++;
        });
      });
      this.$http
        .post('/configuration/permission-module-profile/update', DataForm)
        .then(res => {
          this.notify("Proceso satisfactorio.", res.data.message, 'primary')
          setTimeout(() => {
            this.$router.push({ name: 'profiles' })
          }, 500);
        })
        .catch(err => {
          this.notify("Error", err.response.data.message, 'danger')
        }).finally(
          () => {
            this.not_loading();
          }
        )
    }
  },


}
</script>